import React from 'react'
import moment from "moment"

import Iconos from "../ClasesFunsiones/Iconos"


export function Fechas(props) {
    let CH = [false,false,false,false,false]
    switch (moment(props.fechas.final, "YYYYMMDD").diff(moment(props.fechas.inicio, "YYYYMMDD"),"d")
    ) {
      case 1:
        CH[0]=true
        break;
      case 7:
        CH[1]=true
        break;
      case 30:
        CH[2]=true
        break;
      case 365:
        CH[3]=true
        break;
      default:
        CH[4]=true
        break;
    }
    let cambiarfecha = (dias)=>{
        console.log(dias)
        props.onChangaDate({inicio:moment().subtract(dias,"d").format("YYYYMMDD"),final:moment().format("YYYYMMDD")})
    }
    let cambiarrango = (e)=>{
        let a = props.fechas
        a[e.target.name] = moment(e.target.value,"YYYY-MM-DD").format("YYYYMMDD")
        props.onChangaDate(a)
    }
    return (
        <div className="CajaFechas">
            <div className="CajaFechas1">
                <BtnFecha onClick={()=>{cambiarfecha(1)}} texto="dia" Check={CH[0]}/>
                <BtnFecha onClick={()=>{cambiarfecha(7)}} texto="semana" Check={CH[1]}/>
                <BtnFecha onClick={()=>{cambiarfecha(30)}} texto="mes" Check={CH[2]}/>
                <BtnFecha onClick={()=>{cambiarfecha(365)}} texto="año" Check={CH[3]}/>
                <BtnFecha texto="rango" Check={CH[4]}/>
            </div>
            <div className="CajaFechas2">
                <label htmlFor="Finicio">Inicio</label>
                <input name="inicio" type="date" id="Finicio" onChange={cambiarrango} value={moment(props.fechas.inicio, "YYYYMMDD").format("YYYY-MM-DD")}/>
                <label htmlFor="Ffinal">final</label>
                <input name="final" type="date" id="Ffinal" onChange={cambiarrango} value={moment(props.fechas.final, "YYYYMMDD").format("YYYY-MM-DD")}/>
            </div>
        </div>
    )
}

export function FiltroModalidades(props) {
    let comp
    if(props.ListaEstudios.cargando){
        comp = "Cargando...."
    }
    if(props.ListaEstudios.error){
        comp = "Error...."
    }
    if(!props.ListaEstudios.cargando && !props.ListaEstudios.error){
        comp = <Modlista {...props}/>
    }
    return (
        <div className="FiltroModalidades">
            {comp}
        </div>
    )
}

const Modlista = (props)=>{
    let filtrar = (e)=>{
        let a = props.filtromod
        if(a.indexOf(e)>-1){
            a.splice(a.indexOf(e),1)
        }else{
            a.push(e)
        }
        props.setfiltromod([...a])
    }
    let modalidades = []
    let a = []
    for (let index = 0; index < props.ListaEstudios.items.length; index++) {
        const element = props.ListaEstudios.items[index];
        if(modalidades.indexOf(element.getmodalidadfull().dicom) === -1){
            modalidades.push(element.getmodalidadfull().dicom)
            a.push(element.getmodalidadfull())
        }
    }
    let Btn = []
    for (let index = 0; index < a.length; index++) {
        Btn.push(<BtnFecha key={index} texto={a[index].texto} Check={props.filtromod.indexOf(a[index].dicom)===-1} onClick={()=>{filtrar(a[index].dicom)}}/>)
    }
    let todo = ()=>{
        let b = []
        if(props.filtromod.length === 0){
            b=modalidades
        }
        props.setfiltromod([...b])
    }
    return(
        <>
        <BtnFecha texto="todos" Check={props.filtromod.length===0} onClick={todo}/>
        {Btn}
        </>
    )
}

export function Setting(props) {
    let cambiarAutoSync = ()=>{
        props.Configuracion.AutoSync?props.onChangeConfig("AutoSync",false):props.onChangeConfig("AutoSync",true)
    }
    return (
        <div className="Setting">
            <BtnFecha texto="auto sync" Check={props.Configuracion.AutoSync} onClick={cambiarAutoSync}/>
        </div>
    )
}

let BtnFecha = (props)=>{
    let check = props.Check?"check activo":"check"
    return(
        <div className="btncontenedor">
            <button className="Boton" onClick={props.onClick}>
                {props.texto}
            </button>
            <div className={check}>
                <Iconos icon="done" color="white"/>
            </div>
        </div>
    )
}