import AppAxios from './axios';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import AppDevice from './device';

let s = "Medicaltec3101"

class StudyItem {
    constructor(obj){
        this.ID = obj.ID
        this.FECHA = obj.FECHA
        this.NOMBRE = obj.NOMBRE
        this.SEXO = obj.SEXO
        this.PAS_ID = obj.PAS_ID
        this.EST_UID = obj.EST_UID
        this.INFORM = obj.INFORM
        this.SERIES = obj.SERIES
    }
    getmodalidad(){
        let mod
        let modtest = FindModality(this)
        switch (modtest) {
          case "CT":
            mod = "Tomografia";
            break;
          case "MR":
            mod = "Resonancia";
            break;
          case "US":
            mod = "Ecografia";
            break;
          case "CR":
            mod = "Rayos x";
            break;
          case "DX":
            mod = "Rayos x DR";
            break;
          case "MG":
            mod = "Mamografia";
            break;
          default:
            mod = modtest;
            break;
        }
        return mod
    }
    getmodalidadfull(){
        let mod
        let modtest = FindModality(this)
        switch (modtest) {
          case "CT":
            mod = "Tomografia";
            break;
          case "MR":
            mod = "Resonancia";
            break;
          case "US":
            mod = "Ecografia";
            break;
          case "CR":
            mod = "Rayos x";
            break;
          case "DX":
            mod = "Rayos x DR";
            break;
          case "MG":
            mod = "Mamografia";
            break;
          default:
            mod = modtest;
            break;
        }
        return {dicom:modtest,texto:mod}
    }
    getsexo(){
        return this.SEXO
    }
    colorsexo(){
        let color
        switch (this.SEXO) {
            case "M":
                color="#025185"
                break;
            case "F":
                color="#ed0558"
                    break;
            default:
                color="#9ea7aa"
                break;
        }
        return color
    }
    getnombre(){
        let array = this.NOMBRE
        let nombre = ''
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if(element === "^"){
                nombre = nombre + " "
            }else{
                nombre = nombre + element
            }
        }
        return nombre
    }
    getfecha(){
        return moment(this.FECHA,"YYYYMMDD").format("DD/MM/YYYY")
    }
    filtrar(filtro){
        if(this.NOMBRE.toUpperCase().indexOf(filtro.toUpperCase()) > -1 || this.PAS_ID.indexOf(filtro.toUpperCase()) > -1){
            return true
        }else{
            return false
        }
    }
    filtrarmod(mods){
        if(mods.indexOf(this.getmodalidadfull().dicom) > -1){
            return false
        }else{
            return true
        }
    }
    ShareWP(envio){
        let token = jwt.sign({ID:this.ID},s,{expiresIn:`${envio.tiempo}h`})
        let mensaje = escribirmensaje(token,this.getmodalidad(),this.getnombre())
        if(envio.numero !== ""){
            window.open(`https://wa.me/${envio.prefijo}${envio.numero}?text=${mensaje}`,'_blank')
        }else{
            alert("numero telefonico invalido")
        }
        return true
    }
    ShareMail(envio){
        return new Promise((Pres,Prej)=>{
            let token = jwt.sign({ID:this.ID},s,{expiresIn:`${envio.tiempo}h`})
            let configuracion = JSON.parse(window.localStorage.getItem('ConfiguracionGeneral'))
            let data = {
                destino:envio.direccion,
                data:`${configuracion.ServicorExterno}/externo/${token}`,
                nombre:this.getnombre(),
                modalidad:this.getmodalidad()
            }
            if(envio.direccion !== ""){
                Pres(data)
            }else{
                Prej(false)
            }
        })
    }
    ShareQR(){
        let token = jwt.sign({ID:this.ID},s,{expiresIn:"48h"})
        let url = `${AppDevice.ExternalServer}/externo/${token}`
        return(url)
    }
    ShareWPcontacto(contacto){
        let token = jwt.sign({ID:this.ID},s,{expiresIn:"48h"})
        let mensaje = escribirmensaje(token,this.getmodalidad(),this.getnombre())
        if(contacto.numero !== ""){
            window.open(`https://wa.me/${contacto.prefijo}${contacto.telefono}?text=${mensaje}`,'_blank')
        }else{
            alert("numero telefonico invalido")
        }
        return true
    }
    ShareMailcontacto(contacto){
        let token = jwt.sign({ID:this.ID},s,{expiresIn:"48h"})
        let configuracion = JSON.parse(window.localStorage.getItem('ConfiguracionGeneral'))
        let data = {
            destino:contacto.correo,
            data:`${configuracion.ServicorExterno}/externo/${token}`,
            nombre:this.getnombre(),
            modalidad:this.getmodalidad()
        }
        return data
    }
    Abririmagenes(filtros){
        window.sessionStorage.setItem("filtrosmain",JSON.stringify({fecahs:filtros.RangoFechasSt,mod:filtros.FiltroModalidades}))
        window.sessionStorage.setItem('EstudioVisor',JSON.stringify(this))
    }
    json(){
        return {
            ID:this.ID,
            FECHA:this.FECHA,
            NOMBRE:this.NOMBRE,
            SEXO:this.SEXO,
            PAS_ID:this.PAS_ID,
            EST_UID:this.EST_UID,
            SERIES:this.SERIES
        }
    }
    async uploadinform(informe){
        let formdata = new FormData()
        formdata.append('files',informe)
        formdata.append('estudio',JSON.stringify(this.json()))
        formdata.append('fechasubida',new Date())
        try{
            let res = await AppAxios.post("/cargarInforme",formdata)
            return (res.data)
        }
        catch(e){
            throw e
        }
    }
    static async GetList(fechas,abortsignal) {
        let res
        try{
            res = await AppAxios.get(`/getestudios?inicio=${fechas.inicio}&final=${fechas.final}`, { signal: abortsignal });
        } catch (error) {
            console.log(error)
        }
        let Estudios = []
        for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            let estudio = new StudyItem(element)
            Estudios.push(estudio)
        }
        return Estudios
    }
}

function escribirmensaje(token,modalidad,NOMBRE){
    //let configuracion = JSON.parse(window.localStorage.getItem('ConfiguracionGeneral'))
    let mensaje = `${AppDevice.InstitutionName}:%0AEstudio de ${modalidad.toUpperCase()} del paciente ${NOMBRE}:%0A
    ${AppDevice.ExternalServer}/externo/${token} - %0APara poder habilitar el estudio responda este mensaje`
    return mensaje
}

function FindModality(study){
    let mod = study.SERIES[0].MODALIDAD
    for (let i = 0; i<study.SERIES.length; i++){
        if(study.SERIES[i].MODALIDAD !== "OT" || study.SERIES[i].MODALIDAD !== "SR"){
            mod = study.SERIES[i].MODALIDAD
            break
        }
    }
    return mod
}

export default StudyItem;